img {
  @include border-radius($global-border-radius/2);

  &.lazyload,
  &.lazyloading {
    @include object-fit(scale-down);
  }

  &.lazyloaded {
    @include object-fit(contain);
  }
}
